const networks = {
  mast_pg: "advred",
  mastheadWebDesktop: "advred",
  mastheadWebMobile: "advred",
  mastheadWebTablet: "advred",
  serpSidebar: "advred",
  serpFooter: "advred",
  serpRTL1: "advred",
  serpRTL2: "advred",
  serpRTL3: "advred",
  leafletsCarousel1: "advred",
  leafletsCarousel2: "advred",
  leafletsCarousel3: "advred",
  leafletsCarousel4: "advred",
  leafletsCarousel5: "advred",
  leafletsCarousel6: "adsense",
  offers_1: "advred",
  offers_2: "advred",
  offers_3: "advred",
  offers_4: "advred",
  leaflet_viewer_internal_1: "advred",
  leaflet_viewer_internal_2: "advred",
  leaflet_viewer_internal_3: "advred",
  leaflet_viewer_bottom: "advred",
  leaflet_viewer_side: "advred",
  leaflet_internal_category: "advred",
};

const adsense = {
  settings: {
    script: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
    client: "ca-pub-5202008517445563",
  },
  slots: {
    leafletsCarousel6: {
      slotID: 9801481062,
      sizes: {
        fixed: {
          width: "215px",
          height: "290px",
        },
      },
    },
    offers_1: {
      passbackSlotID: 5729393863,
      breakpoint: 1100,
      large: {
        h: 90,
        w: 728,
        zoneID: 538789,
      },
      small: {
        h: 250,
        w: 300,
        zoneID: 538791,
      },
    },
    offers_2: {
      passbackSlotID: 5729393863,
      breakpoint: 1100,
      large: {
        h: 90,
        w: 728,
        zoneID: 538789,
      },
      small: {
        h: 250,
        w: 300,
        zoneID: 538791,
      },
    },
    offers_3: {
      passbackSlotID: 5729393863,
      breakpoint: 1100,
      large: {
        h: 90,
        w: 728,
        zoneID: 538789,
      },
      small: {
        h: 250,
        w: 300,
        zoneID: 538791,
      },
    },
    offers_4: {
      passbackSlotID: 5729393863,
      breakpoint: 1100,
      large: {
        h: 90,
        w: 728,
        zoneID: 538789,
      },
      small: {
        h: 250,
        w: 300,
        zoneID: 538791,
      },
    },
    serpRTL1: {
      passbackSlotID: 5729393863,
      breakpoint: 1100,
      large: {
        h: 90,
        w: 728,
        zoneID: 538789,
      },
      small: {
        h: 250,
        w: 300,
        zoneID: 538791,
      },
    },
    serpRTL2: {
      passbackSlotID: 7206127068,
      breakpoint: 1100,
      large: {
        h: 90,
        w: 728,
        zoneID: 538799,
      },
      small: {
        h: 250,
        w: 300,
        zoneID: 538801,
      },
    },
    serpRTL3: {
      passbackSlotID: 8682860265,
      breakpoint: 1100,
      large: {
        h: 90,
        w: 728,
        zoneID: 538806,
      },
      small: {
        h: 250,
        w: 300,
        zoneID: 538810,
      },
    },
  },
};

const dfp = {
  settings: {
    dfpScript: "https://www.googletagservices.com/tag/js/gpt.js",
    yieldBirdScript:
      "//dmcdn.yieldbird.com/800cff85-496d-4147-b1c8-a7f0a734ebbd/yb.js",
    yieldLoveScript: "//cdn-a.yieldlove.com/v2/yieldlove.js?promoqui.it",
    doubleClickScript: "//securepubads.g.doubleclick.net/tag/js/gpt.js",
  },
  slots: {
    mast_pg: {
      code: "13389527",
      target: "Mast_PG",
      dimensions: [[970, 250]],
      method: "22028685973",
      targeting: [],
      sizeMapping: [[[0, 0], [[970, 250]]]],
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    mastheadWebDesktop: {
      code: "13389527",
      target: "Masthead_Web1300x260",
      dimensions: [
        [320, 100],
        [728, 90],
        [970, 250],
      ],
      method: "21689325952",
      targeting: [],
      sizeMapping: [
        [[1300, 768], [[970, 250]]],
        [[1024, 740], [[728, 90]]],
        [[740, 1024], [[728, 90]]],
        [[0, 0], [[320, 100]]],
      ],
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    mastheadWebMobile: {
      code: "13389527",
      target: "Masthead_Web_Mobile",
      dimensions: [
        [320, 100],
        [320, 50],
      ],
      method: "22379050047",
      targeting: [],
      sizeMapping: [
        [
          [375, 667],
          [
            [320, 100],
            [320, 50],
          ],
        ],
        [[0, 0], [[320, 50]]],
      ],
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    mastheadWebTablet: {
      code: "13389527",
      target: "Masthead_Web_Tablet",
      dimensions: [[728, 90]],
      method: "22379104190",
      targeting: [],
      sizeMapping: [[[0, 0], [[728, 90]]]],
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    offers_1: {
      code: "13389527",
      target: "Offers_1",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759169395",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[728, 90]]],
        [[740, 1024], [[728, 90]]],
        [[0, 0], [[300, 250]]],
      ],
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    offers_2: {
      code: "13389527",
      target: "Offers_2",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759169422",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[728, 90]]],
        [[740, 1024], [[728, 90]]],
        [[0, 0], [[300, 250]]],
      ],
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    offers_3: {
      code: "13389527",
      target: "Offers_3",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759249577",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[728, 90]]],
        [[740, 1024], [[728, 90]]],
        [[0, 0], [[300, 250]]],
      ],
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    offers_4: {
      code: "13389527",
      target: "Offers_4",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759249643",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[728, 90]]],
        [[740, 1024], [[728, 90]]],
        [[0, 0], [[300, 250]]],
      ],
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    serpRTL1: {
      code: "13389527",
      target: "Retailer_1",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759158499",
      targeting: [],
      sizeMapping: [
        [[1280, 800], [[728, 90]]],
        [[800, 1280], [[300, 250]]],
        [[0, 0], [[300, 250]]],
      ],
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    serpRTL2: {
      code: "13389527",
      target: "Retailer_Category_2",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759246769",
      targeting: [],
      sizeMapping: [
        [[1280, 800], [[728, 90]]],
        [[800, 1280], [[300, 250]]],
        [[0, 0], [[300, 250]]],
      ],
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    serpRTL3: {
      code: "13389527",
      target: "Retailer_Category_3",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759246943",
      targeting: [],
      sizeMapping: [
        [[1280, 800], [[728, 90]]],
        [[800, 1280], [[300, 250]]],
        [[0, 0], [[300, 250]]],
      ],
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    serpFooter: {
      code: "13389527",
      target: "Retailer_Category_4",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759248026",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[728, 90]]],
        [[0, 0], [[300, 250]]],
      ],
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    serpSidebar: {
      code: "13389527",
      target: "Halfpage",
      dimensions: ["fluid", [300, 250], [300, 600]],
      method: "22299599453",
      targeting: [],
      sizeMapping: [],
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    leafletsCarousel1: {
      code: "13389527",
      target: "Native_01",
      dimensions: ["fluid", [200, 200], [180, 150], [250, 250]],
      method: "21807580733",
      targeting: [],
      sizeMapping: [],
      maxRefreshAttempts: 0,
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    leafletsCarousel2: {
      code: "13389527",
      target: "Native_02",
      dimensions: ["fluid", [200, 200], [180, 150], [250, 250]],
      method: "22298698184",
      targeting: [],
      sizeMapping: [],
      maxRefreshAttempts: 0,
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    leafletsCarousel3: {
      code: "13389527",
      target: "Native_03",
      dimensions: ["fluid", [200, 200], [180, 150], [250, 250]],
      method: "22298892768",
      targeting: [],
      sizeMapping: [],
      maxRefreshAttempts: 0,
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    leafletsCarousel4: {
      code: "13389527",
      target: "Native_04",
      dimensions: ["fluid", [200, 200], [180, 150], [250, 250]],
      method: "22309037122",
      targeting: [],
      sizeMapping: [],
      maxRefreshAttempts: 0,
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    leafletsCarousel5: {
      code: "13389527",
      target: "Native_05",
      dimensions: ["fluid", [200, 200], [180, 150], [250, 250]],
      method: "22309039528",
      targeting: [],
      sizeMapping: [],
      maxRefreshAttempts: 0,
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    leaflet_viewer_internal_1: {
      code: "13389527",
      target: "Leaflet_Int",
      dimensions: [
        [336, 280],
        [300, 600],
        [300, 250],
      ],
      method: "21781315049",
      targeting: [],
      sizeMapping: [
        [
          [0, 0],
          [
            [336, 280],
            [300, 250],
          ],
        ],
        [[1300, 768], [[300, 600]]],
      ],
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    leaflet_viewer_internal_2: {
      code: "13389527",
      target: "Leaflet_INT_2",
      dimensions: [
        [336, 280],
        [300, 600],
        [300, 250],
      ],
      method: "21783095770",
      targeting: [],
      sizeMapping: [
        [
          [0, 0],
          [
            [336, 280],
            [300, 250],
          ],
        ],
        [[1300, 768], [[300, 600]]],
      ],
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    leaflet_viewer_internal_3: {
      code: "13389527",
      target: "Leaflet_INT_3",
      dimensions: [
        [336, 280],
        [300, 600],
        [300, 250],
      ],
      method: "21783225987",
      targeting: [],
      sizeMapping: [
        [
          [0, 0],
          [
            [336, 280],
            [300, 250],
          ],
        ],
        [[1300, 768], [[300, 600]]],
      ],
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    leaflet_viewer_bottom: {
      code: "13389527",
      target: "leaflet_bottom",
      dimensions: [
        [320, 50],
        [320, 100],
        [728, 90],
      ],
      method: "21783513777",
      targeting: [],
      sizeMapping: [
        [[728, 1], [[728, 90]]],
        [[1, 640], [[320, 100]]],
        [[0, 0], [[320, 50]]],
      ],
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    leaflet_viewer_side: {
      code: "13389527",
      target: "Side_Adv_300x600",
      dimensions: [
        [160, 600],
        [300, 600],
      ],
      method: "21755590837",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[300, 600]]],
        [[0, 0], [[160, 600]]],
      ],
      enabledYieldbird: true,
      enabledYieldlove: false,
    },
    leaflet_internal_category: {
      code: "13389527",
      target: "leaflet_internal_category",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759169395",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[728, 90]]],
        [[740, 1024], [[728, 90]]],
        [[0, 0], [[300, 250]]],
      ],
      enabledYieldbird: true,
      enabledYieldlove: false,
      state: false,
    },
  },
};

const criteo = {
  settings: {
    script: "https://static.criteo.net/js/ld/publishertag.js",
  },
  slots: {
    mast_pg: {
      code: "13389527",
      target: "Mast_PG",
      dimensions: [[970, 250]],
      method: "22028685973",
      targeting: [],
      sizeMapping: [[[0, 0], [[970, 250]]]],
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    mastheadWebDesktop: {
      code: "13389527",
      target: "Masthead_Web1300x260",
      dimensions: [
        [728, 90],
        [970, 250],
      ],
      method: "21689325952",
      targeting: [],
      sizeMapping: [
        [[1300, 768], [[970, 250]]],
        [[0, 0], [[728, 90]]],
      ],
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    mastheadWebMobile: {
      code: "13389527",
      target: "Masthead_Web_Mobile",
      dimensions: [
        [320, 100],
        [320, 50],
      ],
      method: "22379050047",
      targeting: [],
      sizeMapping: [
        [
          [375, 667],
          [
            [320, 100],
            [320, 50],
          ],
        ],
        [[0, 0], [[320, 50]]],
      ],
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    mastheadWebTablet: {
      code: "13389527",
      target: "Masthead_Web_Tablet",
      dimensions: [[728, 90]],
      method: "22379104190",
      targeting: [],
      sizeMapping: [[[0, 0], [[728, 90]]]],
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    offers_1: {
      code: "13389527",
      target: "Offers_1",
      dimensions: [
        [728, 90],
        [300, 250],
      ],
      method: "21759169395",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[728, 90]]],
        [[740, 1024], [[728, 90]]],
        [[0, 0], [[300, 250]]],
      ],
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    offers_2: {
      code: "13389527",
      target: "Offers_2",
      dimensions: [
        [728, 90],
        [300, 250],
      ],
      method: "21759169422",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[728, 90]]],
        [[740, 1024], [[728, 90]]],
        [[0, 0], [[300, 250]]],
      ],
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    offers_3: {
      code: "13389527",
      target: "Offers_3",
      dimensions: [
        [728, 90],
        [300, 250],
      ],
      method: "21759249577",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[728, 90]]],
        [[740, 1024], [[728, 90]]],
        [[0, 0], [[300, 250]]],
      ],
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    offers_4: {
      code: "13389527",
      target: "Offers_4",
      dimensions: [
        [728, 90],
        [300, 250],
      ],
      method: "21759249643",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[728, 90]]],
        [[740, 1024], [[728, 90]]],
        [[0, 0], [[300, 250]]],
      ],
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    serpRTL1: {
      code: "13389527",
      target: "Retailer_1",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759158499",
      targeting: [],
      sizeMapping: [
        [[1280, 800], [[728, 90]]],
        [[800, 1280], [[300, 250]]],
        [[0, 0], [[300, 250]]],
      ],
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    serpRTL2: {
      code: "13389527",
      target: "Retailer_Category_2",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759246769",
      targeting: [],
      sizeMapping: [
        [[1280, 800], [[728, 90]]],
        [[800, 1280], [[300, 250]]],
        [[0, 0], [[300, 250]]],
      ],
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    serpRTL3: {
      code: "13389527",
      target: "Retailer_Category_3",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759246943",
      targeting: [],
      sizeMapping: [
        [[1280, 800], [[728, 90]]],
        [[800, 1280], [[300, 250]]],
        [[0, 0], [[300, 250]]],
      ],
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    serpFooter: {
      code: "13389527",
      target: "Retailer_Category_4",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759248026",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[728, 90]]],
        [[0, 0], [[300, 250]]],
      ],
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    serpSidebar: {
      code: "13389527",
      target: "Halfpage",
      dimensions: ["fluid", [300, 250], [300, 600]],
      method: "22299599453",
      targeting: [],
      sizeMapping: [],
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    leafletsCarousel1: {
      code: "13389527",
      target: "Native_01",
      dimensions: ["fluid", [200, 200], [180, 150], [250, 250]],
      method: "21807580733",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[250, 250]]],
        [[740, 1024], [[200, 200]]],
        [[0, 0], [[180, 150]]],
      ],
      maxRefreshAttempts: 0,
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    leafletsCarousel2: {
      code: "13389527",
      target: "Native_02",
      dimensions: ["fluid", [200, 200], [180, 150], [250, 250]],
      method: "22298698184",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[250, 250]]],
        [[740, 1024], [[200, 200]]],
        [[0, 0], [[180, 150]]],
      ],
      maxRefreshAttempts: 0,
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    leafletsCarousel3: {
      code: "13389527",
      target: "Native_03",
      dimensions: ["fluid", [200, 200], [180, 150], [250, 250]],
      method: "22298892768",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[250, 250]]],
        [[740, 1024], [[200, 200]]],
        [[0, 0], [[180, 150]]],
      ],
      maxRefreshAttempts: 0,
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    leafletsCarousel4: {
      code: "13389527",
      target: "Native_04",
      dimensions: ["fluid", [200, 200], [180, 150], [250, 250]],
      method: "22309037122",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[250, 250]]],
        [[740, 1024], [[200, 200]]],
        [[0, 0], [[180, 150]]],
      ],
      maxRefreshAttempts: 0,
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    leafletsCarousel5: {
      code: "13389527",
      target: "Native_05",
      dimensions: ["fluid", [200, 200], [180, 150], [250, 250]],
      method: "22309039528",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[250, 250]]],
        [[740, 1024], [[200, 200]]],
        [[0, 0], [[180, 150]]],
      ],
      maxRefreshAttempts: 0,
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    leaflet_viewer_internal_1: {
      code: "13389527",
      target: "Leaflet_Int",
      dimensions: ["fluid", [336, 280], [300, 600], [300, 250]],
      method: "21781315049",
      targeting: [],
      sizeMapping: [
        [
          [0, 0],
          [
            [336, 280],
            [300, 250],
          ],
        ],
        [[1300, 768], [[300, 600]]],
      ],
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    leaflet_viewer_internal_2: {
      code: "13389527",
      target: "Leaflet_INT_2",
      dimensions: ["fluid", [336, 280], [300, 600], [300, 250]],
      method: "21783095770",
      targeting: [],
      sizeMapping: [
        [
          [0, 0],
          [
            [336, 280],
            [300, 250],
          ],
        ],
        [[1300, 768], [[300, 600]]],
      ],
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    leaflet_viewer_internal_3: {
      code: "13389527",
      target: "Leaflet_INT_3",
      dimensions: ["fluid", [336, 280], [300, 600], [300, 250]],
      method: "21783225987",
      targeting: [],
      sizeMapping: [
        [
          [0, 0],
          [
            [336, 280],
            [300, 250],
          ],
        ],
        [[1300, 768], [[300, 600]]],
      ],
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    leaflet_viewer_bottom: {
      code: "13389527",
      target: "leaflet_bottom",
      dimensions: [
        [320, 50],
        [320, 100],
        [728, 90],
      ],
      method: "21783513777",
      targeting: [],
      sizeMapping: [
        [[728, 1], [[728, 90]]],
        [[1, 640], [[320, 100]]],
        [[0, 0], [[320, 50]]],
      ],
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
    leaflet_viewer_side: {
      code: "13389527",
      target: "Side_Adv_300x600",
      dimensions: [
        [160, 600],
        [300, 600],
      ],
      method: "21755590837",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[300, 600]]],
        [[0, 0], [[160, 600]]],
      ],
      networkid: 10948,
      publishersubid: "web_promoqui",
      itemRanges: null,
    },
  },
};

const advred = {
  settings: {
    adsRedCode: "adv_call_promoqui",
  },
  slots: {
    mast_pg: {
      code: "13389527",
      target: "Mast_PG",
      dimensions: [[970, 250]],
      method: "22028685973",
      targeting: [],
      sizeMapping: [[[0, 0], [[970, 250]]]],
    },
    mastheadWebDesktop: {
      code: "13389527",
      target: "Masthead_Web1300x260",
      dimensions: [
        [320, 100],
        [728, 90],
        [970, 250],
      ],
      method: "21689325952",
      targeting: [],
      sizeMapping: [
        [[1300, 768], [[970, 250]]],
        [[1024, 740], [[728, 90]]],
        [[740, 1024], [[728, 90]]],
        [[0, 0], [[320, 100]]],
      ],
    },
    mastheadWebMobile: {
      code: "13389527",
      target: "Masthead_Web_Mobile",
      dimensions: [
        [320, 100],
        [320, 50],
      ],
      method: "22379050047",
      targeting: [],
      sizeMapping: [
        [
          [375, 667],
          [
            [320, 100],
            [320, 50],
          ],
        ],
        [[0, 0], [[320, 50]]],
      ],
    },
    mastheadWebTablet: {
      code: "13389527",
      target: "Masthead_Web_Tablet",
      dimensions: [[728, 90]],
      method: "22379104190",
      targeting: [],
      sizeMapping: [[[0, 0], [[728, 90]]]],
    },
    offers_1: {
      code: "13389527",
      target: "Offers_1",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759169395",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[728, 90]]],
        [[740, 1024], [[728, 90]]],
        [[0, 0], [[300, 250]]],
      ],
    },
    offers_2: {
      code: "13389527",
      target: "Offers_2",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759169422",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[728, 90]]],
        [[740, 1024], [[728, 90]]],
        [[0, 0], [[300, 250]]],
      ],
    },
    offers_3: {
      code: "13389527",
      target: "Offers_3",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759249577",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[728, 90]]],
        [[740, 1024], [[728, 90]]],
        [[0, 0], [[300, 250]]],
      ],
    },
    offers_4: {
      code: "13389527",
      target: "Offers_4",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759249643",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[728, 90]]],
        [[740, 1024], [[728, 90]]],
        [[0, 0], [[300, 250]]],
      ],
    },
    serpRTL1: {
      code: "13389527",
      target: "Retailer_1",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759158499",
      targeting: [],
      sizeMapping: [
        [[1280, 800], [[728, 90]]],
        [[800, 1280], [[300, 250]]],
        [[0, 0], [[300, 250]]],
      ],
    },
    serpRTL2: {
      code: "13389527",
      target: "Retailer_Category_2",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759246769",
      targeting: [],
      sizeMapping: [
        [[1280, 800], [[728, 90]]],
        [[800, 1280], [[300, 250]]],
        [[0, 0], [[300, 250]]],
      ],
    },
    serpRTL3: {
      code: "13389527",
      target: "Retailer_Category_3",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759246943",
      targeting: [],
      sizeMapping: [
        [[1280, 800], [[728, 90]]],
        [[800, 1280], [[300, 250]]],
        [[0, 0], [[300, 250]]],
      ],
    },
    serpFooter: {
      code: "13389527",
      target: "Retailer_Category_4",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759248026",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[728, 90]]],
        [[0, 0], [[300, 250]]],
      ],
    },
    serpSidebar: {
      code: "13389527",
      target: "Halfpage",
      dimensions: ["fluid", [300, 250], [300, 600]],
      method: "22299599453",
      targeting: [],
      sizeMapping: [],
    },
    leafletsCarousel1: {
      code: "13389527",
      target: "Native_01",
      dimensions: ["fluid", [200, 200], [180, 150], [250, 250]],
      method: "21807580733",
      targeting: [],
      sizeMapping: [],
      maxRefreshAttempts: 0,
    },
    leafletsCarousel2: {
      code: "13389527",
      target: "Native_02",
      dimensions: ["fluid", [200, 200], [180, 150], [250, 250]],
      method: "22298698184",
      targeting: [],
      sizeMapping: [],
      maxRefreshAttempts: 0,
    },
    leafletsCarousel3: {
      code: "13389527",
      target: "Native_03",
      dimensions: ["fluid", [200, 200], [180, 150], [250, 250]],
      method: "22298892768",
      targeting: [],
      sizeMapping: [],
      maxRefreshAttempts: 0,
    },
    leafletsCarousel4: {
      code: "13389527",
      target: "Native_04",
      dimensions: ["fluid", [200, 200], [180, 150], [250, 250]],
      method: "22309037122",
      targeting: [],
      sizeMapping: [],
      maxRefreshAttempts: 0,
    },
    leafletsCarousel5: {
      code: "13389527",
      target: "Native_05",
      dimensions: ["fluid", [200, 200], [180, 150], [250, 250]],
      method: "22309039528",
      targeting: [],
      sizeMapping: [],
      maxRefreshAttempts: 0,
    },
    leaflet_viewer_internal_1: {
      code: "13389527",
      target: "Leaflet_Int",
      dimensions: [
        [336, 280],
        [300, 600],
        [300, 250],
      ],
      method: "21781315049",
      targeting: [],
      sizeMapping: [
        [
          [0, 0],
          [
            [336, 280],
            [300, 250],
          ],
        ],
        [[1300, 768], [[300, 600]]],
      ],
    },
    leaflet_viewer_internal_2: {
      code: "13389527",
      target: "Leaflet_INT_2",
      dimensions: [
        [336, 280],
        [300, 600],
        [300, 250],
      ],
      method: "21783095770",
      targeting: [],
      sizeMapping: [
        [
          [0, 0],
          [
            [336, 280],
            [300, 250],
          ],
        ],
        [[1300, 768], [[300, 600]]],
      ],
    },
    leaflet_viewer_internal_3: {
      code: "13389527",
      target: "Leaflet_INT_3",
      dimensions: [
        [336, 280],
        [300, 600],
        [300, 250],
      ],
      method: "21783225987",
      targeting: [],
      sizeMapping: [
        [
          [0, 0],
          [
            [336, 280],
            [300, 250],
          ],
        ],
        [[1300, 768], [[300, 600]]],
      ],
    },
    leaflet_viewer_bottom: {
      code: "13389527",
      target: "leaflet_bottom",
      dimensions: [
        [320, 50],
        [320, 100],
        [728, 90],
      ],
      method: "21783513777",
      targeting: [],
      sizeMapping: [
        [[728, 1], [[728, 90]]],
        [[1, 640], [[320, 100]]],
        [[0, 0], [[320, 50]]],
      ],
    },
    leaflet_viewer_side: {
      code: "13389527",
      target: "Side_Adv_300x600",
      dimensions: [
        [160, 600],
        [300, 600],
      ],
      method: "21755590837",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[300, 600]]],
        [[0, 0], [[160, 600]]],
      ],
    },
    leaflet_internal_category: {
      code: "13389527",
      target: "leaflet_internal_category",
      dimensions: ["fluid", [728, 90], [300, 250]],
      method: "21759169395",
      targeting: [],
      sizeMapping: [
        [[1024, 600], [[728, 90]]],
        [[740, 1024], [[728, 90]]],
        [[0, 0], [[300, 250]]],
      ],
      state: false,
    },
  },
};

const ads = {
  disabled: false,
  enablePlaceholderForBot: true,
  networks: networks,
  adsense: adsense,
  dfp: dfp,
  criteo: criteo,
  advred: advred,
};

export default ads;
